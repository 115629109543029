.settingsContainer {
    display: flex;
    height: 100%;
}

.settingsNavigation {
    flex: 0 0 200px;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.navItem {
    padding: 10px 15px;
    cursor: pointer;
    border-left: 5px solid transparent;
    transition: background-color 0.3s, border-left-color 0.3s, border-radius 0.3s;
    border-radius: 6px;
    text-decoration: none;
    color: #24292f;
}

.navItem:hover {
    background-color: #f6f8fa;
}

.navItem.active {
    border-left-color: #007bff;
    background-color: #e7f1ff;
    color: #0366d6;
    border-radius: 6px;
}

.navItem:visited {
    color: #24292f;
}

.settingsContent {
    flex-grow: 1;
    padding: 20px;
}

.closeButton {
    margin: 10px 0 10px 0;
}


.firstActionIcon {
    cursor: pointer;
}

.actionIcon {
    margin-left: 10px;
    cursor: pointer;
}

/*Table styles*/
.settingsTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    color: #24292f;
    font-size: 14px;
    line-height: 20px;
}

.settingsTable th,
.settingsTable td {
    padding: 8px 16px;
    text-align: left;
    vertical-align: top;
    border: 1px solid #d0d7de;
}

.settingsTable th {
    background-color: #f6f8fa;
    font-weight: 600;
}

.settingsTable tbody tr:hover {
    background-color: #f6f8fa;
}
