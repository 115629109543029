button {
    user-select: none;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    padding: 6px;
    margin: 10px;
}

form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Spacing between form elements */
    background-color: white;
    border-radius: 5px;
}

form label {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

form label input {
    margin-top: 2px;
}

form input[type="text"], form input[type="password"], form input[type="email"], form input[type="number"], form input[type="date"], form input[type="tel"] {
    padding: 8px;
    border-radius: 6px;
}

form button {
    margin: 10px 0;
    padding: 8px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 6px;
}

form button:hover {
    background-color: #45a049;
}
