.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
}

.loginCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: calc(20%);
}

.loginCenter h1 {
    margin-bottom: 15px;
    font-weight: 300;
}

h2 {
    font-weight: 300;
}

h3 {
    font-weight: 300;
}

.formStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px; /* Add some space between the form and the Telegram login button */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.inputStyle, .buttonStyle {
    width: 300px; /* Adjust width as needed */
    display: block; /* Block level for consistent alignment */
    box-sizing: border-box; /* Include padding and border in width/height */
}

.inputStyle {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.buttonStyle {
    margin-top: 20px; /* Increased top margin for separation from inputs */
}

.signUpButton {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.signUpButton:hover {
    background-color: #0056b3;
}
