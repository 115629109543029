.dayTypeForm {
    display: flex;
    align-items: center;
}

.colorCircle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}
