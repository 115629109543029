.mainContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
}

.footer {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 0.8em;
}

.iconContainer {
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 5000;
    display: flex;
    gap: 20px; /* Adjust this value based on desired spacing between icons */
}

.reportIcon {
    cursor: pointer;
}

.loadingIndicator {
    position: fixed;
    top: 10px;
    right: 100px;
    cursor: default;
    z-index: 5000;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
}

.loadingIndicator:not(:empty) {
    opacity: 1;
}

.userIcon {
    /*width: 30px; !* Adjust size as needed *!*/
    /*height: 30px; !* Adjust size as needed *!*/
    background-color: #f0f0f0;
    border-radius: 30px; /* Makes it circular */
    display: flex;
    align-items: center;
    justify-content: center;
    /*font-size: 19px; !* Adjust font size as needed *!*/
    cursor: pointer;
    user-select: none;
}